@font-face {
  font-family: "Montserrat Medium";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat ExtraBold";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans SemiBold";
  src: url("../fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans Regular";
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto ExtraBold";
  src: url("../fonts/Roboto/Roboto-BoldCondensed.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/Inter/Inter-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Trueno Rg";
  src: url("../fonts/Trueno/TruenoRg.otf");
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.header-col {
  width: 1512px;
  background: #0a814c;
}

.header-col-body {
  width: 100%;
}

.logo-text {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 41px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #fdffff;
  mix-blend-mode: normal;
}

.header-menu {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}

.header-link:hover {
  text-decoration: none;
}

.logo-img {
  width: 110px;
}

.welcome-backgound {
  background-image: url("../img/home/welcome_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 9.3%;
  padding-bottom: 9.3%;
  width: 100%;
}

.welcome-backgound-body {
  width: 60%;
}

.welcome-backgound-gradient {
  background: linear-gradient(180deg, #1f1f1f 0%, rgba(217, 217, 217, 0) 100%);
  margin-top: 0%;
}

.welcome-text1 {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
}

.welcome-text2 {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  color: #ffffff;
}

.welcome-btn {
  width: 355px;
  height: 85px;

  background: rgba(40, 236, 149, 0.87);
  border-radius: 42.5px;
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  border: none;
  color: #000000;
}

.welcome-btn:focus {
  border: none;
  outline: none;
}

.welcome-mobile-parent {
  padding-left: 6%;
}

.welcome-mobile {
  width: 18.2vw;
}

.partner-text1 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: #000000;
}

.partner-text2 {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  padding-top: 3.5% !important;
  color: #555555;
}

.partner-event {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-bottom: 12px;
}

.partner-event-img {
  width: 100%;
}

.event-card {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.event-card-text1 {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #a7a7a7;
}

.event-card-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #e1aa1d;
}

.event-card-text3 {
  font-family: "Trueno Rg";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.event-card-text4 {
  font-family: "Poppins Regular";
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #72ca2d;
}

.currently-play {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #000000;
}

.about-text1 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 90px;
  color: #000000;
}

.about-text2 {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
  color: #000000;
}

/* about animation  */

.about-section {
  opacity: 0.5;
  transform: translateY(50px);
  transition: 3s all;
}

.about-section.active {
  opacity: 1;
  transform: translateY(0);
}

.footer-background {
  background-image: url("../img/home/Footer banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 9%;
}

.footer-text1 {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 120px;
  color: #ffffff;
}

.footer-text2 {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
}

.footer-text2-body {
  width: 60%;
}

.football-text-line {
  background-color: white;
  width: 179.5px;
  height: 0px;
  border: 4px solid #6f6f6f;
}

.footer-bootom-line {
  /* width: 1513.08px; */
  height: 0px;
  border: 1px solid #a3a3a3;
}

.footer-gmail {
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0c9041;
}

.footer-gmail:hover {
  color: #0c9041;
  text-decoration: none;
}

.Carousel-background {
  background-image: url("../img/home/ballsbg.png");
  background-size: 65%;
  background-repeat: no-repeat;
}

.sport-partner-background {
  background-image: url("../img/home/sport_partner_background.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.footer-right {
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0c9041;
}

.footer-policy {
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding-top: 8px;
  color: #0c9041;
}

.social-icon {
  width: 40px;
}

.google-button {
  cursor: pointer;
  transition: 1s all;
}

.google-button-clicked {
  animation-name: click-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.apple-button {
  cursor: pointer;
  transition: 1s all;
}

.apple-button-clicked {
  animation-name: click-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes click-animation {
  0% {
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
  }
}

.about-img-parent {
  height: 550px;
}

.about-img {
  width: 100%;
  height: 100%;
}

.google-button:hover,
.apple-button:hover {
  opacity: 0.56;
  box-shadow: 4px 7px 24px 0px rgb(0 0 0 / 28%);
}

.slider-img{
  object-fit: cover;
}

@media (min-width: 1000px) and (max-width: 1225px) {
  .partner-event {
    width: 115%;
  }
  .partner-event-img {
    width: 113%;
  }
  .welcome-mobile {
    width: 18vw;
  }
}

@media (min-width: 262px) and (max-width: 1800px) {
  .welcome-backgound-gradient {
    margin-top: -1px;
  }
}

@media (min-width: 262px) and (max-width: 1000px) {
  .logo-img {
    width: 13vw;
  }

  .logo-text {
    padding-left: 2%;
    font-size: 43px;
  }

  .players-play {
    width: 30vw;
  }

  .partner-event {
    width: 105%;
  }
  .partner-event-img {
    width: 100%;
  }

  .footer-text2-body {
    width: 75%;
  }

  .footer-text1 {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 0px;
    color: #ffffff;
  }

  .footer-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
  }

  .football-text-line {
    background-color: white;
    width: 179.5px;
    height: 0px;
    border: 2px solid #6f6f6f;
  }

  .footer-background {
    padding-top: 0%;
  }

  .partner-text1 {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #000000;
  }

  .partner-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #555555;
  }

  .about-text1 {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 40px;
    color: #000000;
  }

  .about-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.76;
    color: #000000;
  }

  .currently-play {
    font-size: 23px;
  }

  .welcome-backgound-body {
    width: 100%;
  }

  .welcome-mobile {
    width: 18vw;
  }

  .welcome-text1 {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #ffffff;
  }

  .welcome-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  .welcome-btn {
    width: 170px;
    height: 45px;
    background: rgba(40, 236, 149, 0.87);
    border-radius: 42.5px;
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 35px;
    color: #000000;
  }

  .apple-button {
    width: 132px;
    height: 38px;
    cursor: pointer;
    transition: 1s all;
  }

  .apple-button-clicked {
    width: 132px;
    height: 38px;
    animation-name: click-apple-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  @keyframes click-apple-animation {
    0% {
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
    }
  }

  .google-button {
    width: 132px;
    height: 38px;
    cursor: pointer;
    transition: 1 all;
  }

  .google-button-clicked {
    width: 132px;
    height: 38px;
    animation-name: click-apple-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  @keyframes click-google-animation {
    0% {
    }
    50% {
      transform: scale(1.002, 1.002);
    }
    100% {
    }
  }

  .google-button-clicked {
    /* width: 85%; */
  }

  .social-icon {
    width: 30px;
  }
}

@media (min-width: 555px) and (max-width: 1000px){
  .partner-event{
width: 90%;
  }

  .partner-event-img{
width: 80%;
margin-top: 1%;
margin-bottom: 1%;
  }



}

@media (min-width: 580px) and (max-width: 900px) {
  .welcome-mobile {
    width: 22vw;
  }
}

@media (min-width: 282px) and (max-width: 420px) {
  .event-card {
    font-size: 18px;
  }

  .event-card-text1 {
    font-size: 12px;
  }
  .event-card-text2 {
    margin-top: 2.5%;
    font-size: 12px;
  }
  .event-card-text3 {
    margin-top: 2.5%;
    font-size: 12px;
  }
  .event-card-text4 {
    margin-top: 2.5%;
    font-size: 12px;
  }
  .header-col-body {
    /* width: auto; */
  }
}

@media (min-width: 282px) and (max-width: 575px) {
  .logo-text {
    font-size: 31px;
  }

  .logo-img {
    width: 9vw;
  }

  .header-menu {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    text-align: center;

    color: #ffffff;
  }

  .welcome-btn {
    /* width: 218px; */
  }

  .welcome-backgound {
    min-height: 93vh;
  }

  .welcome-mobile {
    width: 63vw;
  }

  .logo-text {
    line-height: 0px;
  }

  .footer-right,
  .footer-gmail {
    font-size: 15px;
  }
  .footer-policy {
    font-size: 11px;
  }

  .partner-event-img {
    width: 85%;
  }

  .about-img-parent {
    height: 400px;
  }
  .about-img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 279px) and (max-width: 380px){
  .header-menu {
    font-size: 11px;
  }

  .logo-text {
    font-size: 23px;
}
}

@media (min-width: 252px) and (max-width: 289px) {
  .event-card {
    font-size: 15px;
  }
  .event-card-text1 {
    font-size: 10px;
  }
  .event-card-text2 {
    margin-top: 2.5%;
    font-size: 10px;
  }
  .event-card-text3 {
    margin-top: 2.5%;
    font-size: 10px;
  }
  .event-card-text4 {
    margin-top: 2.5%;
    font-size: 10px;
  }
  .header-col-body {
    width: auto;
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .remove_mobile {
    /* display: none; */
  }

  .welcome-mobile-parent {
    padding-left: 9%;
  }

  .google-button:hover,
.apple-button:hover {
  opacity: 1;
  box-shadow: 4px 7px 24px 0px rgb(0 0 0 / 28%);
}
}

@media (min-width: 766px) and (max-width: 1001px) {
  .footer-text1 {
    padding-top: 10px !important;
  }

  .footer-background {
    padding-top: 10%;
  }
}

@media (min-width: 999px) and (max-width: 2525px) {
  .players-play {
    width: 280px;
  }

  .download-icon {
    width: 333px;
    height: 96px;
  }
}


@media (min-width:1024px) and (max-width: 1280px) {
  .welcome-text1 {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: #ffffff;
  }
  
  .welcome-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #ffffff;
  }
  
  .welcome-btn {
    width: 205px;
    height: 55px;
  
    background: rgba(40, 236, 149, 0.87);
    border-radius: 42.5px;
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 45px;
    border: none;
    color: #000000;
  }
}

@media (min-width:1280px) and (max-width: 1480px) {
  .welcome-text1 {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #ffffff;
  }
  
  .welcome-text2 {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
  }
  
  .welcome-btn {
    width: 205px;
    height: 55px;
  
    background: rgba(40, 236, 149, 0.87);
    border-radius: 42.5px;
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 45px;
    border: none;
    color: #000000;
  }
}

@media (min-width:576px) and (max-width: 641px){
  .welcome-mobile {
    width: 24vw;
  }
}

@media (min-width:1920px) and (max-width: 2680px){
  .welcome-mobile {
    max-width: 12vw; /* Set the maximum width to 100% */
  height: auto;
  }

  .welcome-backgound{
    background-position: center top -162px;
  }
}

@media (min-width:998px) and (max-width: 1040px){
  .welcome-mobile{
    width: 27vw;
  }
}